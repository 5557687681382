.page__case_study__nrgasm {
  .work__ill {
    br {
      display: none !important;
    }
  }
  .ills__row {
    overflow: hidden;
    width: 1100px;
    margin-left: -350px;

    .work__ill {
      width: 500px;
      float: left;
      img {
        width: 100%;
        height: auto;
      }
    }
    .work__ill:nth-child(2n+1){
      margin-left: 40px;
    }
  }
  .work__ill.work__nrgasm__illustration-01
  {
      position: absolute;
      top: 0;
      left: 50px;

      width: 484px;

      small
      {
          font-size: 75%;

          position: absolute;
          bottom: 0;

          display: block;
          clear: both;

          padding-left: 14px;
      }
  }
  .work__nrgasm__illustration-02 {
    width: 1100px;
    margin-left: -350px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .work__screen
  {
      width: 1100px;
      height: auto;
      margin-bottom: 10px;
      margin-left: -350px;
      br {
        display: none !important;
      }
      img
      {
          width: 100%;
          height: auto;
      }
      small
      {
          display: block;
          clear: both;

          padding-left: 65px;
      }
  }

  .work__nrgasm__illustration-01
  {
      top: 0;

      height: 342px;
  }
}
