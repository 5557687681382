@font-face {
  font-family: "PFBeauSansPro-Light";
  src: url("PFBeauSansPro-Light_2.eot");
  src: url("PFBeauSansPro-Light_2.eot?#iefix") format("embedded-opentype"),
         url("PFBeauSansPro-Light_2.woff2") format("woff2"),
         url("PFBeauSansPro-Light_2.woff") format("woff"),
         url("PFBeauSansPro-Light_2.ttf") format("ttf"),
         url("PFBeauSansPro-Light_2.svg#PFBeauSansPro-Light") format("svg");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "PFBeauSansPro-Regular";
  src: url("PFBeauSansPro-Reg_2.eot");
  src: url("PFBeauSansPro-Reg_2.eot?#iefix") format("embedded-opentype"),
         url("PFBeauSansPro-Reg_2.woff2") format("woff2"),
         url("PFBeauSansPro-Reg_2.woff") format("woff"),
         url("PFBeauSansPro-Reg_2.ttf") format("ttf"),
         url("PFBeauSansPro-Reg_2.svg#PFBeauSansPro-Regular") format("svg");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "PFBeauSansPro-Thin";
  src: url("PFBeauSansPro-Thin_2.eot");
  src: url("PFBeauSansPro-Thin_2.eot?#iefix") format("embedded-opentype"),
         url("PFBeauSansPro-Thin_2.woff2") format("woff2"),
         url("PFBeauSansPro-Thin_2.woff") format("woff"),
         url("PFBeauSansPro-Thin_2.ttf") format("ttf"),
         url("PFBeauSansPro-Thin_2.svg#PFBeauSansPro-Thin") format("svg");
  font-style: normal;
  font-weight: extra-light;
}