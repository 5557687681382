.page__case_study__hakkageneration {
  .content__main {
    h3 {
      margin-top: 140px;
    }
    p {
      line-height: 1.8;
    }
  }

  .work__ill.work__hakkageneration__illustration-11 {
    left: 0;
    vertical-align: bottom;
    img {
      display: inline-block;
      width: 100px;
      float: left;
      margin-top: -10px;
      margin-right: 20px;
    }
  }

  .work__ill.work__hakkageneration__illustration-01
  {
      position: absolute;
      top: -30px;
      left: 30px;

      width: 484px;
      small
      {
          font-size: 75%;

          position: absolute;
          bottom: 0;

          display: block;
          clear: both;

          padding-left: 14px;
      }
  }

  .work__ill {
    position: relative;
    left: -430px;
    width: 100%;
    padding-bottom: 40px;

    small {
      position: relative;
      left: 430px;
      bottom: -10px;
    }
    &.work__hakkageneration__illustration-06
    {
      small {
        width: 400px;
        position: absolute;
        bottom: 160px;
        left: 430px;
      }
    }
    &.work__hakkageneration__illustration-07
    {
      small {
        width: 400px;
        position: absolute;
        bottom: 160px;
        left: 430px;
      }
    }
    &.work__hakkageneration__illustration-08
    {
      small {
        width: 400px;
        position: absolute;
        bottom: 160px;
        left: 430px;
      }
    }
  }

  .work__screen
  {
      width: 900px;
      height: auto;
      margin-bottom: 180px;
      margin-left: -250px;
      img
      {
          width: 100%;
          height: auto;
      }
      small
      {
          display: block;
          clear: both;

          padding-left: 65px;
      }
  }

  .screenshots {
    position: relative;
    width: 1200px;
    left: -500px;
    height: 3500px;

    .work__hakkageneration__screen-01 {
      position: absolute;
      left: 400px;
      top: 0px;
      width: 1170px;
      height: auto;
    }

    .work__hakkageneration__screen-02 {
      position: absolute;
      left: 300px;
      top: 730px;
      width: 900px;
      height: auto;
    }
    .work__hakkageneration__screen-03 {
      position: absolute;
      left: 400px;
      top: 1300px;
      width: 1170px;
      height: auto;
    }
  }

}
