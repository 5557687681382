.page__case_study__tomilino {
  @keyframes fadeOne
  {
      0%  { opacity: 0; }

      20% { opacity: 1; }

      40% { opacity: 0; }

      60% { opacity: 0; }

      80% { opacity: 0; }

     100% { opacity: 0; }
  }

  @keyframes fadeTwo
  {
      0%  { opacity: 0; }

      20% { opacity: 0; }

      40% { opacity: 1; }

      60% { opacity: 0; }

      80% { opacity: 0; }

     100% { opacity: 0; }
  }

  @keyframes fadeThree
  {
      0%  { opacity: 0; }

      20% { opacity: 0; }

      40% { opacity: 0; }

      60% { opacity: 1; }

      80% { opacity: 0; }

     100% { opacity: 0; }
  }

  @keyframes fadeFour
  {
      0%  { opacity: 0; }

      20% { opacity: 0; }

      40% { opacity: 0; }

      60% { opacity: 0; }

      80% { opacity: 1; }

     100% { opacity: 1; }
  }

  .work__ill.work__tomilino__illustration-01
  {
      position: absolute;
      top: 0;
      left: 90px;

      width: 484px;
      small
      {
          font-size: 75%;

          position: absolute;
          bottom: 0;

          display: block;
          clear: both;

          padding-left: 14px;
      }
  }
  .work__tomilino__illustration-02ani {
    width: 500px;
    height: 130px;
    position: absolute;
    top: 350px;
    left: 0;
    text-align: center;

    img {
      position: absolute;
      left: 0;
      top: 0;
    }

    .ill-2-1 {
      left: 36px;
      top: 34px;
      opacity: 0;
      animation-name: fadeFour;
      animation-duration: 6s;
      animation-iteration-count: infinite;
    }
    .ill-2-2 {
      left: 39px;
      opacity: 0;
      top: 18px;
      animation-name: fadeThree;
      animation-duration: 6s;
      animation-iteration-count: infinite;
    }
    .ill-2-3 {
      left: 73px;
      opacity: 0;
      top: 5px;
      animation-name: fadeTwo;
      animation-duration: 6s;
      animation-iteration-count: infinite;
    }
    .ill-2-4 {
      left: 85px;
      top: 0px;
      opacity: 0;
      animation-name: fadeOne;
      animation-duration: 6s;
      animation-iteration-count: infinite;
    }
  }

  .work__tomilino__screen-01 {
    position: relative;
    left: -250px;
  }

  .work__tomilino__screen-02 {
    position: relative;
    left: 0px;
    margin-top: -400px;
  }


  .work__screen
  {
      width: 900px;
      height: auto;
      margin-bottom: 10px;
      margin-left: -250px;
      img
      {
          width: 100%;
          height: auto;
      }
      small
      {
          display: block;
          clear: both;

          padding-left: 65px;
      }
  }

  .work__ills_row {
    position: relative;
    left: -300px;
    width: 900px;
    padding-bottom: 100px;
    .work__ill {
      float: left;
      width: 280px;
      height: 390px;
      img {
        width: 100%;
        height: auto;
      }
    }
    small {
      position: absolute;
      display: block;
      width: 200px;
      right: 120px;
      top: 410px;
    }
  }
}
