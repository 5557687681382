.page__case_study__alexandreev {
  .work__ill.work__alexandreev__illustration-01
  {
      position: absolute;
      top: 0;
      left: 30px;

      width: 484px;
      small
      {
          font-size: 75%;

          position: absolute;
          bottom: 0;

          display: block;
          clear: both;

          padding-left: 14px;
      }
  }
  .work__screen
  {
      width: 900px;
      height: auto;
      margin-bottom: 50px;
      margin-left: -250px;
      img
      {
          width: 100%;
          height: auto;
      }
      small
      {
          display: block;
          clear: both;

          padding-left: 65px;
      }
  }

}
