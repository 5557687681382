.page__case_study__ivanrastr {
  .work__ill.work__ivanrastr__illustration-01
  {
      position: absolute;
      top: 0;
      left: 30px;

      width: 484px;
      small
      {
          font-size: 75%;

          position: absolute;
          bottom: 0;

          display: block;
          clear: both;

          padding-left: 14px;
      }
  }

  .work__ivanrastr__illustration-02 {
    width: 1100px;
    margin-left: -360px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .work__screen
  {
      width: 900px;
      height: auto;
      margin-bottom: 0;
      margin-left: 0px;
      img
      {
          width: 100%;
          height: auto;
      }
      small
      {
          display: block;
          clear: both;

          padding-left: 65px;
      }
  }

  .work__ivanrastr__screen-01 {
    position: relative;
    top: 0px;
    left: -400px;
  }

  .work__ivanrastr__screen-02 {
    position: relative;
    top: -500px;
    z-index: -1;
  }

  .work__ivanrastr__screen-03 {
    position: relative;
    top: -500px;
    left: -400px;
    z-index: 1;
  }

  .work__ivanrastr__screen-04 {
    position: relative;
    top: -900px;
    z-index: -1;
  }

  .work__ivanrastr__screen-05 {
    margin-top: -1000px;
    margin-left: -400px;
  }
}
