.page__case_study__primeestate
{
    .work__ill
    {
        position: absolute;
        top: 0;
        left: 30px;

        width: 484px;
        small
        {
            font-size: 75%;

            position: absolute;
            bottom: 0;

            display: block;
            clear: both;

            padding-left: 14px;
        }
    }
    .work__screen
    {
        width: 900px;
        height: auto;
        margin-bottom: 180px;
        margin-left: -250px;
        img
        {
            width: 100%;
            height: auto;
        }
        small
        {
            display: block;
            clear: both;

            padding-left: 65px;
        }
    }

    .content__main h2
    {
        width: 430px;
    }

    .work__primeestate__illustration-01
    {
        top: 0;

        height: 342px;
    }
    .work__primeestate__illustration-02
    {
        top: 400px !important;

        height: 396px;
        .ill-2-1
        {
            position: absolute;
            top: 90px;
            left: 0;
        }
        .ill-2-2
        {
            position: absolute;
            top: 0;
            right: 5px;
        }
    }
    .work__primeestate__illustration-03
    {
        top: 900px !important;

        height: 490px;
        .ill-3-1
        {
            position: absolute;
            top: 0;
        }
        .ill-3-2
        {
            position: absolute;
            top: 80px;
            right: 5px;
        }
        .ill-3-3
        {
            position: absolute;
            top: 230px;
        }
        small
        {
            width: 90%;
        }
    }
    .work__primeestate__illustration-04
    {
        top: 1500px !important;

        height: 660px;
        .ill-4-1
        {
            position: absolute;
            bottom: 20px;
            left: 0;
        }
        .ill-4-2
        {
            position: absolute;
            bottom: 90px;
            left: 100px;
        }
        .ill-4-3
        {
            position: absolute;
            right: 0;
            bottom: 10px;
        }
    }
    .work__primeestate__illustration-05
    {
        top: 700px;

        display: none;
    }
}
