.page__case_study__yastro
{
    @keyframes rotationIll
    {
        from
        {
            transform: rotate(-360deg);
        }

        to
        {
            transform: rotate(0deg);
        }
    }
    .content,
    .design
    {
        position: relative;

        padding: 200px 0 400px 200px;
    }

    .content
    {
        left: -290px;

        padding-top: 800px;
        padding-right: 300px;
        padding-left: 0;
    }

    .work__ill.work__yastro__illustration-01
    {
        position: absolute;
        top: 0;
        left: 30px;

        width: 484px;
        small
        {
            font-size: 75%;

            position: absolute;
            bottom: 0;

            display: block;
            clear: both;

            padding-left: 14px;
        }
    }
    .work__screen
    {
        width: 900px;
        height: auto;
        margin-bottom: 180px;
        margin-left: -250px;
        img
        {
            width: 100%;
            height: auto;
        }
        small
        {
            display: block;
            clear: both;

            padding-left: 65px;
        }

        &.work__yastro__screen-03,
        &.work__yastro__screen-02,
        &.work__yastro__screen-01
        {
            position: absolute;

            width: 790px;
        }

        &.work__yastro__screen-01
        {
            top: 110px;
            left: -320px;
            small
            {
                font-size: 16px;

                position: absolute;
                top: 700px;
                left: 1000px;

                display: block;

                width: 280px;
                padding: 0;
            }
        }
        &.work__yastro__screen-02
        {
            top: 500px;
            left: -100px;
        }
        &.work__yastro__screen-03
        {
            top: 150px;
            left: 550px;
        }
    }

    .work__ill.work__yastro__illustration-02
    {
        position: absolute;
        top: 350px;
        left: 140px;

        width: 246px;
        height: 246px;
        img
        {
            animation-name: rotationIll;
            animation-duration: 10s;
            animation-iteration-count: infinite;
        }
    }

    .work__ill.work__yastro__illustration-03
    {
        position: absolute;
        top: 850px;
        left: 60px;

        width: 390px;

        img
        {
            width: 100%;
            height: auto;
        }
    }

    .work__ill.work__yastro__illustration-04
    {
        position: absolute;
        top: 1000px;
        left: 60px;

        width: 390px;

        img
        {
            width: 100%;
            height: auto;
        }
    }

    .work__ill.work__yastro__illustration-05
    {
        position: absolute;
        left: 0;

        width: 100%;

        img
        {
            width: 100%;
        }
    }

    .work__ill.work__yastro__illustration-06
    {
        padding: 100px 0 0 0;
        height: 660px;
        .ill-6-1
        {
            top: 200px;
            left: 0;
        }
        .ill-6-2
        {
            position: relative;
            top: -800px;
            left: -500px;
        }
    }

    .work__yastro__illustration-07 {
      position: relative;
      width: 1000px;
      left: -320px;
      img {
        width: 300px;
        float: left;
      }
      small {
        display: block;
        clear: both;
        padding-left: 320px;
      }
    }



    #yastro-svg-scheme-placeholder
    {
        // background: #efefef;
    }
}
