.page__case_study__imperial {
  .content__main {
    h3 {
      margin-top: 140px;
    }
    p {
      line-height: 1.8;
    }
  }
  .work__ill.work__imperial__illustration-01
  {
      position: absolute;
      top: 0;
      left: 30px;

      width: 484px;
      small
      {
          font-size: 75%;

          position: absolute;
          bottom: 0;

          display: block;
          clear: both;

          padding-left: 14px;
      }
  }
  .work__screen
  {
      width: 900px;
      height: auto;
      margin-bottom: 180px;
      margin-left: -250px;
      img
      {
          width: 100%;
          height: auto;
      }
      small
      {
          display: block;
          clear: both;

          padding-left: 65px;
      }
      &.work__imperial__screen-01 {
        margin-top: 1200px;
      }
  }

  .work__imperial__illustration-02
  {
      position: absolute;
      top: 350px;

      left: 13px;
      // border: 1px solid red;

      height: 675px;
      width: 500px;

      small {
        position: absolute;
        bottom: 0;
        left: 16px;
        display: block;
      }
      img {
        width: 400px;
      }

      .ill-2-1,
      .ill-2-2,
      .ill-2-3 {
        position: absolute;
      }

      .ill-2-2 {
        top: 130px;
        left: 80px;
      }

      .ill-2-3 {
        top: 220px;
        left: 0px;
      }
  }

  .work__imperial__illustration-03 {
    position: absolute;
    top: 1100px;

    left: 13px;

    height: 850px;
    width: 500px;

    small {
      position: absolute;
      bottom: 0;
      left: 16px;
      display: block;
    }

    img {
      width: 480px;
    }
  }

  .work__imperial__illustration-04 {
    left: 0;
    position: absolute;
    width: 1400px;
    height: 1200px;
    .ill-4-1,
    .ill-4-2,
    .ill-4-3,
    .ill-4-5 {
      position: absolute;
    }
    .ill-4-1 {
      top: 90px;
      left: 14px;
    }
    .ill-4-2 {
      top: 350px;
      left: 250px;
    }
    .ill-4-3 {
      top: 0;
      left: 550px;
    }
    .ill-4-5 {
      top: 380px;
      left: 850px;
    }
  }
}
