.page__case_study__labelmen {
  .work__ill.work__labelmen__illustration-01
  {
      position: absolute;
      top: 0;
      left: 80px;

      width: 484px;
      small
      {
          font-size: 75%;

          position: absolute;
          bottom: 0;

          display: block;
          clear: both;

          padding-left: 14px;
      }
  }
  .work__screen
  {
      width: 900px;
      height: auto;
      margin-bottom: 30px;
      margin-left: -250px;
      img
      {
          width: 100%;
          height: auto;
      }
      small
      {
          display: block;
          clear: both;

          padding-left: 65px;
      }
  }

  .work__imperial__tomilino-01
  {
      top: 0;

      height: 342px;
  }
}
