/**
  basic SASS file
  project: bzz
  date: 23.03.2017 12:31:50
  author: alex@buzzfriends.ru
*/

body
{
    font-family: 'PFBeauSansPro-Regular', Arial, 'Helvetica Neue', Helvetica, sans-serif;

    margin: 0;
    padding: 0;

    color: #4a4a4a;
    background: #fff;
}

.hidden
{
    display: none !important;
}

.icon
{
    display: inline-block;

    vertical-align: middle;
}

.header__main
{
    height: 130px;

    color: #fff;
    background: #0081bf;
    .wrapper
    {
        overflow: hidden;
        // width: 1440px;

        margin: 0 auto;
    }
    .logo__top
    {
        float: left;

        padding: 28px 50px;
    }
    .contacts__top
    {
        float: left;

        width: 300px;
        margin-top: 35px;
        margin-left: 113px;
        .email,
        .telephone
        {
            height: 2em;
        }
        .icon
        {
            padding-right: 10px;
        }
        a
        {
            color: #fff;
        }
    }
    .slogan__block
    {
        float: left;

        width: 200px;
        height: 4em;
        margin: 25px 0 0 0;
    }

    .icon__menu
    {
        float: right;

        padding: 45px 50px 45px 50px;

        cursor: pointer;
        &.icon__menu__active
        {
            background: #17bef0;
            svg
            {
                transition: all 1s;
                transform: rotate(90deg);
            }
        }
        svg
        {
            transition: all 1s;
            &:hover
            {
                opacity: .5;
            }
        }
    }
}

.header__sub
{
    font-size: 55px;

    position: relative;

    overflow: hidden;

    margin-bottom: 56px;

    color: #fff;
    background: #26acec;
    .nav__breadcrumbs
    {
        line-height: 1;

        float: left;

        padding: 28px 50px 16px 46px;

        vertical-align: middle;

        .project__name
        {
            font-size: 44px;

            display: inline-block;

            width: 398px;
            padding-right: 10px;
        }

        .project__url
        {
            font-size: 44px;

            display: inline-block;

            padding-left: 0;
        }
    }
    .nav__breadcrumbs__active
    {
        float: right;

        padding: 28px 50px 16px 50px;

        background: #0085bd;
    }

    .icon__menu
    {
        position: absolute;
        z-index: 10000;
        top: 40px;
        right: 6em;

        display: block;

        width: 66px;
        height: 28px;

        cursor: pointer;

        &.icon__menu__active
        {
            opacity: 1;
        }
        svg
        {
            display: block;

            margin: 0;
            padding: 0;
        }
    }
}

.page__works .header__sub .icon__menu
{
    display: none;
}




.nav__main ul
{
    display: block;

    margin: 0;
    padding: 0;
    li
    {
        display: block;

        margin: 0;
        padding: 0;

        list-style: none;
        a
        {
            text-decoration: none;
        }
    }
}

.page__level2 .nav__main
{
    font-size: 55px;

    position: absolute;
    z-index: 100;
    top: 130px;
    right: 0;

    width: 220px;
    padding: 28px 50px 16px 50px;

    text-align: right;

    color: #fff;
    color: #0085bd;
    background: #17bef0;
    a
    {
        color: #fff;
    }
}

.page__index .nav__main
{
    width: 500px;
    margin: 0 auto;
    padding-bottom: 300px;
    a
    {
        font-size: 77px;

        color: #0085bd;
        &:hover
        {
            color: #00c0ed;
        }
    }
}

.page__level2 .nav__cases,
.page__level2 .nav__works
{
    font-size: 55px;

    position: absolute;
    z-index: 100;
    top: 130px;
    right: 5em;

    display: inline-block;

    padding: 28px 50px 16px 50px;

    color: #fff;
    background: #17bef0;
    ul
    {
        display: block;

        margin: 0;
        padding: 0;
        li
        {
            display: block;

            margin: 0;
            padding: 0;

            list-style: none;

            color: #0085bd;

            a
            {
                text-decoration: none;

                color: #fff;
                &:hover
                {
                    background: #0085bd;
                }
            }
        }
    }
}

.page__index .content__slogan
{
    font-size: 77px;
    font-size: 41px;

    width: 500px;
    margin: 60px auto;

    color: #0085bd;
}

.clients__grid
{
    width: 1440px;
    margin: 0 auto;
    .row
    {
        clear: both;
        overflow: hidden;

        margin: 0 0 50px 0;
        padding: 10px 0 0 50px;
        .client__block
        {
            display: block;
            float: left;

            width: 400px;
            height: 241px;
            margin: 0;
            padding: 0;

            text-align: center;

            border: 1px solid rgba(155, 155, 155, .3);
            &:nth-child(2n)
            {
                margin: 0 70px;
            }
            &:hover
            {
                border: 1px solid #00c0ed;
                box-shadow: 0 1px 3px 3px rgba(0,191,237,.17);
            }

            &.client__has_no_link
            {
                opacity: .3;
            }

            &.client__has_no_link:hover
            {
                border: 1px solid rgba(155, 155, 155, .3);
                box-shadow: none;
            }

            a
            {
                display: inline-block;

                width: 400px;
                height: 241px;

                text-align: center;
                vertical-align: middle;
            }
            svg
            {
                width: 400px;
                padding: 0;

                text-align: center;
            }
        }
    }
}

.content__main
{
    width: 1440px;
    margin: 0 auto;
    .content__column__prices__intro h3
    {
        margin-top: 40px;
    }
    h1,
    h2,
    h3,
    h4,
    h5
    {
        font-weight: normal;

        color: #0081bf;
    }
    a
    {
        color: #0081bf;
    }
}

.content__column__prices__intro,
.content__column__clients__intro
{
    width: 574px;
    margin-bottom: 57px;
    margin-left: 550px;
}

.content__column__works__intro
{
    width: 574px;
    margin-bottom: 57px;
    margin-left: 550px;
}

.works__grid .works__row
{
    display: block;
    clear: both;
    overflow: hidden;

    margin: 0 0 60px 0;
    padding: 0;
    .work__block
    {
        float: left;

        width: 448px;
        margin: 0 5%;
        .work__ill__block
        {
            display: table-cell;

            width: 448px;
            height: 300px;

            text-align: center;
            vertical-align: middle;

            // background: rgba(123, 123, 123, .05);
            img
            {
                display: inline-block;

                max-width: 80%;
                height: auto;
                max-height: 80%;

                vertical-align: middle;
            }
        }
        p
        {
            line-height: 1.5;
            em
            {
                font-style: normal;

                display: inline-block;

                padding: 2px 3px 0 4px;

                color: #fff;
                border-radius: 2px;
                background: #0085bd;
            }
        }
    }
}

.page__case_study .content__main
{
    position: relative;

    width: 574px;
    margin: 0;
    margin-bottom: 57px;
    padding-left: 550px;
    p
    {
        font-size: 16px;

        color: #4a4a4a;
    }
    h2
    {
        font-size: 30px;
    }
    h3
    {
        font-size: 26px;

        margin-top: 120px;
    }
    h4
    {
        color: #00c0ed;
    }
    p
    {
        line-height: 1.5;
        em
        {
            font-style: normal;

            display: inline-block;

            padding: 2px 3px 0 4px;

            color: #fff;
            border-radius: 2px;
            background: #0085bd;
        }
    }
}
